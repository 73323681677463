import React from "react";
import MenuBar from "../components/MenuBar";
import { Helmet } from "react-helmet";
import backgroundImage from "../assets/northstar/bg_img.png";
import useURLQueryParameter from "../hooks/queryParamHook";
import { Link } from "gatsby";
import ElevateCard from "../components/ElevateCard";
import { ErrorCodes, SUPPORT_EMAIL } from "../constants";

const ErrorPage: React.FC = () => {
  const [errorCode] = useURLQueryParameter("code");

  let retriable = true;

  switch (errorCode) {
    case ErrorCodes.OktaUnauthorizedClient:
    case ErrorCodes.OktaAccessDenied:
    case ErrorCodes.OktaUnsupportedResponseType:
    case ErrorCodes.OktaUnsupportedResponseMode:
    case ErrorCodes.OktaInvalidScope:
    case ErrorCodes.OktaInvalidClient:
    case ErrorCodes.OktaLoginRequired:
    case ErrorCodes.OktaInvalidRequest:
    case ErrorCodes.OktaUserCanceledRequest:
      retriable = false;
      break;

    case ErrorCodes.InternalLoginTimeout:
    case ErrorCodes.OktaServerError:
    case ErrorCodes.OktaTemporarilyUnavailable:
      retriable = true;
      break;
  }

  let subject = "Unexpected error in Northstar";

  if (Object.values(ErrorCodes).includes(errorCode)) {
    subject += ` (code ${errorCode})`;
  }

  const emailAnchor = (
    <a
      href={`mailto:${SUPPORT_EMAIL}?subject=${subject}`}
      className="text-blue"
    >
      {SUPPORT_EMAIL}
    </a>
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Error</title>
      </Helmet>

      <main className="w-screen h-screen bg-off-white flex flex-center">
        <MenuBar NorthstarMenu={true} />
        <div
          className="px-8 pt-24 md:pt-32 bg-white flex justify-center items-center h-full w-full"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
          }}
        >
          <div className="w-[360px] h-auto">
            <ElevateCard title="Oops!">
              <div className="grid grid-cols-1 gap-y-1">
                <div>
                  Something went wrong.&nbsp;
                  {retriable
                    ? "Please try again."
                    : "This error isn't retriable."}
                </div>
                <div>
                  {retriable
                    ? "If this issue persists after retrying, please contact us for assistance:"
                    : "Please contact us for assistance:"}
                </div>
                <div className="text-center">{emailAnchor}</div>
                <div>
                  Otherwise,{" "}
                  <Link to="/login">
                    <span className="hover:font-bold hover:text-blue">
                      click here
                    </span>
                  </Link>{" "}
                  to go back to the login page.
                </div>
              </div>
            </ElevateCard>
          </div>
        </div>
      </main>
    </>
  );
};

export default ErrorPage;
